<template>
  <div class="main van-clearfix">
    <!-- <div 
      style="opacity: .6;position:fixed;bottom: 120px;right: 20px;border-radius: 30px;padding: 20px 12px;background-color: #1989fa;color: #fff;z-index: 999;"
      @click="()=>{this.$router.push('/info/wx996229f81e6a88f8')}"
    >
      back
    </div> -->
    <van-tabs v-model="active" @click="onclickPay"  animated sticky>
      <van-tab v-for="index of 5" :key="index">
        <template #title>
          <div>
            {{index == 1?'全部':index == 2?'待付款':index == 3?'付款成功':index == 5?'已退款':'付款失败'}}
            <!-- <van-badge v-if="index == 2" style="margin-top:-2px;" v-show="tagNum>0" :content="tagNum" /> -->
          </div>
        </template>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
        
          <div v-for="(item, key) in orderList" :key="key"
          @touchstart="gtouchstart(item)"
          @touchmove="gtouchmove()"
          @touchend="showDeleteButton(item)"
          >
            <van-divider
              :style="{
                color: '#e1e1e1',
                borderColor: '#e1e1e1',
                margin: '0px',
                padding: '0 4px',
              }"
            />
            <van-row style="padding: 10px; background-color: white">
              <van-col span="6" style="padding-top: 10px">
                <van-image width="80" height="80" :src="img" />
              </van-col>
              <van-col span="12" style="padding-top: 10px">
                <span class="custom-title">{{ item.comboTitle }}</span
                ><br />
                <span style="font-size: 13px; color: gray">{{
                  item.comboName
                }}</span>
              </van-col>
              <van-col span="6" style="text-align: right; padding-top: 10px">
                <span class="custom-title">￥{{ item.payableMoney }}</span
                ><br />
                <!-- <span style="color: gray;font-size: 14px;">X1</span> -->
              </van-col>
            </van-row>
            <van-row style="padding: 10px; background-color: white">
              <van-col span="24" style="text-align: right">
                应付金额：<span :style="{
                  color:  item.orderStat == 0?'#390':
                          item.orderStat == 1?'#e6a024':
                          item.orderStat == 2?'#aaa':
                          item.orderStat == 3?'#f05041':
                          '#000'
                }">
                <!-- ￥{{ item.paymentType == 5?'0':item.payableMoney }}</span -->
                ￥{{ item.payableMoney }}</span
                >
              </van-col>
            </van-row>
            <van-row style="padding: 10px; background-color: white;">
              <van-col span="12" style="text-align: left; color: gray">{{
                item.payTime
              }}</van-col>
              <van-col span="12" style="text-align: right;margin-top: -4px;"
                >订单状态：
              <!-- 0"充值成功", 1"待支付", 2"充值失败", 3"已退款" -->
                <span :style="{
                  color:  item.orderStat == 0?'#390':
                          item.orderStat == 1?'#e6a024':
                          item.orderStat == 2?'#aaa':
                          item.orderStat == 3?'#f05041':
                          '#000'
                }">
                {{
                  this.status[item.orderStat]||item.remark
                }}
                </span>
              </van-col>
            </van-row>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
    <!--弹出层，确认是否购买以及介绍套餐详情-->
    <van-popup
      v-model:show="show"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div style="position:sticky;top:0;background-color: white;z-index: 99;">
        <div style="height:10px;"></div>
        <van-card
          :tag="itemTag"
          :price="itemPrice"
          :desc="itemDesc"
          thumb="/images/fg.png"
          :title="itemTitle"
          :origin-price="itemOriginPrice"
        />
          <!-- :thumb="this.$store.getters.corp == 1?'/images/zgyd.png':this.$store.getters.corp == 2?'/images/zglt.png':'/images/zgdx.png'" -->
      </div>
      <van-divider>套餐描述</van-divider>
      <div class="tips" style="white-space: pre-wrap">
        <p v-for="(tag, key) in this.goods.tags" :key="key" class="tipsItem">
          <span style="font-size: 0.5rem;margin-right:5px;">●</span> 
          <span>{{ tag }}</span>
        </p>
        <p>{{ itemDesc }}</p>
      </div>
      <van-radio-group v-model="payType"  style="height: 500px;">
        <van-cell-group>
          <van-cell
            title="微信支付"
            icon="chat-o"
            clickable
            @click="choosePayType(1)"
            v-show="payStat == 1"
          >
            <template #right-icon>
              <van-radio :name="1" icon="passed" />
            </template>
          </van-cell>
          <!-- 如果不是待支付状态就不显示 -->
          <van-cell
            title="余额支付"
            icon="paid"
            clickable
            @click="choosePayType(3)"
            v-show="payStat == 1"
          >
            <template #right-icon>
              <van-radio :disabled ="balance < itemPrice" :name="3" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-submit-bar
        :loading="payLoading"
        :tip="payStat == 1?'请仔细确认套餐信息':''"
        tip-icon="info-o"
        :disabled="payStat != 1"
        :price="payPrice"
        :button-text="payStat == 0?'已支付':payStat == 1?'确认支付':payStat == 2?'支付失败':payStat == 3?'已退款':payStat == 4?'已关闭':'未知'"
        decimal-length="2"
        @submit="buyCombo"
      />
    </van-popup>
    <common-footer />
  </div>
</template>
<script>
import { getCombos, selectOrderCombo, createOrder, removeOrder } from "@/api/card";
import commonFooter from "@/views/common/footer.vue";
import wechatUtil from "@/utils/wechatUtil";
import { Dialog,Notify,Toast } from "vant";
import store from "@/store";
import Cookies from "js-cookie";
export default {
  name: "order",
  components: {
    commonFooter,
  },
  data() {
    return {
      active: 0,
      orderList: [],
      status: ["充值成功", "待支付", "充值失败", "已退款"],
      total: 0,
      orderStat: "",
      loading: false,
      payLoading: false,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      flag: false,
      img: "",
      tagFlag:true,
      tagNum:0,
      comboActive: 0,
      goods: null,
      payPrice: 0,
      itemPrice: 0,
      itemOriginPrice: 0,
      itemTitle: "商品标题",
      itemDesc: "描述信息",
      show: false,
      payType: 1,
      payStat:0,
      orderId:undefined,
      timeOutEvent:undefined,
      balance:0,
    };
  },
  beforeRouteLeave(to, form, next) {
    console.log(to, form)
    next()
  },
  created() {
    this.getOrder();
    // if (this.$store.getters.corp == 1) {
    //   this.img = "/images/zgyd.png";
    // } else if (this.$store.getters.corp == 2) {
    //   this.img = "/images/zglt.png";
    // } else {
    //   this.img = "/images/zgdx.png";
    // }
    this.img = "/images/fg.png";
    this.getTagNum()
    
    this.$store.dispatch("getCardInfo", {
          iccid:Cookies.get('card-id'),
          officialId: localStorage.getItem('resFlog1'),
        })
        .then((res) => {
          console.log(res)
          this.balance = res.balance;
        });
  } ,
  watch:{
    'show':'downShow'
  },
  methods: {
    
    // 选择支付方式时触发
    choosePayType(payId){
      // 微信
      if(payId == 1){
        this.payType = payId
      }
      // 余额
      else if(payId == 3){
        if(this.balance < this.itemPrice){
          this.payType = 1
          Toast({
                  message: "余额不足",
                  position: "bottom",
                });
        }else{
          this.payType = payId
        }
      }
      
    },
    // 订单弹窗关闭时，按钮加载一起停止
    downShow(newer,older){
      if(!newer){
        this.loading = false
      }
    },
    // 更新未付款订单数
    getTagNum(){
      
      let iccid = Cookies.get('card-id');
      selectOrderCombo(
        iccid,
        1,
        this.pageNum,
        this.pageSize
      ).then((res) => {
        this.tagNum = res.total
      })
    },
    //长按事件（起始）
    gtouchstart(item) {
      var self = this;
      this.timeOutEvent = setTimeout(function () {
        self.longPress(item);
      }, 500); //这里设置定时器，定义长按500毫秒触发长按事件
      return false;
    },
    //手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
    showDeleteButton(item) {
      clearTimeout(this.timeOutEvent); //清除定时器
      if (this.timeOutEvent != 0) {
        //这里写要执行的内容（如onclick事件）
        this.choosePay(item)
      }
      return false;
    },
    //如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按
    gtouchmove() {
      clearTimeout(this.timeOutEvent); //清除定时器
      this.timeOutEvent = 0;
    },
    //真正长按后应该执行的内容
    longPress(order) {
      this.timeOutEvent = 0;
      //执行长按要执行的内容，如弹出菜单
      if(order.orderStat == 1 || localStorage.getItem('admin') == 1)//待支付订单才可删除
      Dialog.confirm({
          title: "标题",
          message: "是否确认删除该订单",
          type: "warning"
      })
      .then(() => {
        
        // alert('暂未开放')
          this.delOrder(order.orderId)
      })
      .catch(() => {
        
      });
    },

    toUrl(rout,realName){
      console.log(rout)
      this.$router.push("/"+rout+'/'+localStorage.getItem('resFlog1'))
      console.log('wid',localStorage.getItem('resFlog1'))
      if(realName){
        store.commit("SET_IS_REAL_NAME", true);
      }
    },
    delOrder(oid){
      removeOrder(oid).then(e=>{
        this.orderList = []
        this.pageNum = 1
        this.getOrder()
        this.getTagNum()
      })
    },
    buyCombo(){
      if(this.payStat == 1){
        this.payLoading = true
      let user = JSON.parse(localStorage.getItem('user-info'))
      let locAppId = localStorage.getItem('resFlog1')
      let iccid = Cookies.get('card-id');
        createOrder(
          iccid,
          this.goods.groupId,
          this.goods.comboId,
          this.payType,
          locAppId||user.officialId,
          user.openid
        ).then((res) => {
          console.log('res',res)
          if (this.payType == "1") {
            wechatUtil.init(["chooseWXPay"]).then((wx) => {
              this.payLoading = false
              console.log( "wx",wx);
              wx.chooseWXPay({
                appId: locAppId,//本地链接的公众号id
                // appId: user.officialId,//本地用户数据的公众号id
                // appId: res.appId,//返回值所带的appid
                timestamp: res.timestamp,
                nonceStr: res.nonce_str,
                package: res.prepay_id,
                signType: res.signType,
                paySign: res.sign,
                success: (e)=> {
                      console.log('支付成功返回值',e)
                      this.show = false;
                      this.delOrder(this.orderId)
                      Notify({ type: 'success', message: '支付成功'});
                      // 成功通知
                      // if(this.$store.getters.realNameAuth==0){
                      //   Dialog.alert({
                      //     title: '提示',
                      //     message: '您还未实名，请实名后使用',
                      //     theme: 'round-button',
                      //   }).then(() => {
                      //     this.toUrl('info',true)
                      //   });
                      // }
                },
                cancel: (e)=>{
                      console.log('支付取消返回值',e)
                      this.show = false; 
                      this.delOrder(res.orderId)
                },
                error: () => {
                  this.show = false;
                  this.getOrder();
                  // 危险通知
                  Notify({ type: 'danger', message: '支付出错' });
                },
                // 接口调用完成时执行的回调函数，无论成功或失败都会执行
                complete: (e)=>{
                }
              });
            });
          } else {
              this.payLoading = false
              // 余额支付
              this.show = false;
              // 成功通知
              Notify({ type: 'success', message: '支付成功' });
          }
        });
      }
    },
    comboChoose(item) {
      console.log(item)
      this.comboActive = item.comboId;
      this.goods = item;
      this.payPrice = item.currentPrice * 100;
      this.itemPrice = item.currentPrice;
      this.itemOriginPrice = item.originalPrice;
      this.itemTitle = item.comboTitle;
      this.itemDesc = item.remark == "null" ? "" : item.remark;
      this.show = true;
    },
    choosePay(item){
      console.log(item)
      this.orderId = item.orderId
      this.payStat = item.orderStat
      console.log('看订单')
        getCombos().then(
        (res) => {
          let lt = res[0].concat(res[1]).concat(res[2]).concat(res[3])
          
          lt.forEach(e => {
            if(e.comboId == item.comboId){
              console.log(e);
              e.tags = e.extendStr ? e.extendStr.split(","):'';
              this.comboChoose(e)
            }
          });
        })
    },
    onLoad() {
      if (this.flag == false) {
        this.flag = true;
        this.loading = true;
        this.getOrder();
        this.loading = false;
        if (this.pageNum * this.pageSize >= this.total) {
          this.finished = true;
        }
      }
    },
    onclickPay(title) {
      this.pageNum = 1;
      this.orderList = [];
      this.loading = false;
      this.finished = false;
      if (title == 0) {
        this.orderStat = ""; //全部
      } else if (title == 1) {
        this.orderStat = 1; //待付款
      } else if (title == 2) {
        this.orderStat = 0; //付款成功
      } else if (title == 3) {
        this.orderStat = 2; //付款失败
      } else if (title == 4) {
        this.orderStat = 3; //已退款
      }
      this.getOrder();
    },
    async getOrder() {
      
      let iccid = Cookies.get('card-id');
      await selectOrderCombo(
        iccid,
        this.orderStat,
        this.pageNum,
        this.pageSize
      ).then((res) => {
        console.log('返回的订单信息',res)
        if (res != null) {
          // this.orderList = this.orderList.concat(res.rows);

          res.rows.forEach((n,i)=>{
            if(n.paymentType != 5 && (n.comboName.indexOf('体验')==-1||n.orderStat == 0)){this.orderList.push(n)}
          })
          
          console.log('订单列表',this.orderList)
          this.total = res.total;
          this.pageNum++;
          this.flag = false;
        }
      });
    },
  },
};
</script>
<style scoped>
html body {
  background-color: #f2f2f2 !important;
  height: 500px;
  overflow-y: visible;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none;
}
.main {
  font-family: pingfang;
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
}
.tips {
    padding: 15px;
}
.tipsItem {
    display:flex;
    /* color: #f05050d8;#32bb25 #f05041 */
    flex-direction: row;
    align-items: center;
}

</style>
